<template>
    <footer class="footer">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 footer-copyright">
            <p class="mb-0">Copyright {{new Date().getFullYear()}} © Scienlabs All rights reserved.</p>
          </div>
          <div class="col-md-6">
            <p class="pull-right mb-0"> <span><a href="https://makeping.com/privacy-policy/" target="_blank">Privacy Policy / Terms</a></span> 
              <span><router-link  :to="{ path: '/app/locations' }">/ Locations & IPs</router-link> </span>
              --- FAQ / 
              <!-- <span>Contact Us</span>   -->
              <!-- --- API -->
            </p>
          </div>
        </div>
      </div>
    </footer>
</template>

<script>
  import json from '../data/menu'
export default {
  name: 'footerpage',
  data(){
    return{
      customizer :false,
      data:"data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(json)),
    }
  }
}
</script>
