<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <form action="" @submit.prevent="onSubmit()">
                    <px-card>
                        <b-row>
                            <b-col md="3" class="font-weight-bold">
                                Friendly Name *
                            </b-col>
                            <b-col md="8">
                                <div class="form-group">
                                    <b-form-input size="sm" type="text" name="name" v-model="input.name" maxlength="50" placeholder="status page name" required></b-form-input>
                                </div>
                            </b-col>
                        </b-row><hr>
                        <b-row>
                            <b-col md="3" class="font-weight-bold">
                                Monitors *
                            </b-col>
                            <b-col md="8">
                                <div class="form-group">
                                    <multiselect
                                    variant="success"
                                    :close-on-select="false"
                                    v-model="selected_monitors"
                                    tag-placeholder="Select Monitors"
                                    placeholder="Search or add a Monitors"
                                    label="text"
                                    track-by="value"
                                    :show-labels="false"
                                    :options="monitor_opt"
                                    :multiple="true"
                                    :taggable="true"
                                    @tag="addTag"
                                    required
                                    >
                                        <template #option="{ option }">
                                           <div class="d-flex justify-content-between">
                                                <span>{{ option.text }}</span>
                                                <span v-if="option.badge" class="badge badge-gray ml-2" style="font-size: 12px;">{{ option.badge }}</span>
                                           </div>
                                        </template>
                                        <template #selected="{ option }">
                                            <div class="d-flex justify-content-between">
                                                <span>{{ option.text }}</span>
                                                <span v-if="option.badge" class="badge badge-gray ml-2" style="font-size: 12px;">{{ option.badge }}</span>
                                            </div>
                                        </template>
                                    </multiselect>
                                </div>
                            </b-col>
                        </b-row><hr>
                        <!-- <b-row>
                            <b-col md="3" class="font-weight-bold">
                                Enable Subscriber
                            </b-col>
                            <b-col md="4">
                                <div class="form-group">
                                    <input class="checkbox_animated" name="enablesubscriber" type="checkbox" v-model="input.enableSubscriber" />
                                </div>
                            </b-col>
                        </b-row><hr> -->
                        <!-- <b-row>
                            <b-col md="3" class="font-weight-bold">
                                Homepage url
                            </b-col>
                            <b-col md="8">
                                <div class="form-group">
                                    <b-form-input size="sm" type="text" name="homepageurl" :state="validate_url"  v-model="input.homepageurl" placeholder="Home Page Url"></b-form-input>
                                    <b-form-invalid-feedback :state="validate_url">The given URL has an invalid character</b-form-invalid-feedback>
                                    <div><small>Set up the URL for a linkable logo / status page name in the header of the Status page.</small></div>
                                </div>
                            </b-col>
                        </b-row><hr> -->
                        <!-- <b-row>
                            <b-col md="3" class="font-weight-bold">
                                Custom Domain
                            </b-col>
                            <b-col md="8">
                                <div class="form-group">
                                    <b-form-input size="sm" type="text" name="customdomain" :state="validate_domain" v-model="input.customDomain" placeholder="use your custom domain"></b-form-input>
                                    <b-form-invalid-feedback :state="validate_domain">The given Domain has an invalid character</b-form-invalid-feedback>
                                </div>
                            </b-col>
                        </b-row><hr> -->
                        <b-row>
                            <b-col md="3" class="font-weight-bold">
                                Password
                            </b-col>
                            <b-col md="8">
                                <div class="form-group">
                                <b-input-group>
                                    <b-form-input size="sm" type="password" id="statuspagepassword" name="password" maxlength="30" v-model="input.pagePassword" placeholder="Password"></b-form-input>
                                    <b-input-group-append is-text @click="togglePassword('statuspagepassword')">
                                        <i v-show="showPassword == true" class="icofont icofont-eye" id="togglePassword" title="hide password"></i>
                                        <i v-show="showPassword == false" class="icofont icofont-eye-blocked" id="togglePassword" title="show password"></i>
                                    </b-input-group-append>
                                </b-input-group>
                                </div>
                            </b-col>
                        </b-row><hr>
                        <!-- <b-row>
                            <b-col md="3" class="font-weight-bold">
                                Google Analytics Code
                            </b-col>
                            <b-col md="8">
                                <div class="form-group">
                                    <b-form-input name="googleAnalyticsCode" v-model="input.googleAnalyticsCode" maxlength="100" placeholder="UA-xxxxxxxxx-x"></b-form-input>
                                    <small class="text-muted">It works only if you have set up a Custom Domain for status page. Please use format like UA-xxxxxxxxx-x or G-xxxxxxxxxx. We are using </small>
                                    <a href="https://developers.google.com/analytics/devguides/collection/gtagjs" target="_blank" rel="noopener noreferrer"><small>Global site tag (gtag.js) - Google Analytics" implementation.</small></a>
                                </div>
                            </b-col>
                        </b-row><hr> -->
                        <b-row>
                            <b-col md="3" class="font-weight-bold">
                                Robots meta tag
                            </b-col>
                            <b-col md="8">
                                <div class="form-group">
                                    <b-form-select
                                        name="type"
                                        size="sm"
                                        v-model="input.robotsMetaTag"
                                        :options="robotsMetaTag_opt"
                                        required
                                    ></b-form-select>
                                    </div>
                            </b-col>
                        </b-row><hr>
                        <b-row class="mt-3 text-center">
                            <b-col>
                                <b-button type="submit" variant="primary" :disabled="disable_submit">{{edit_status?'Edit Status Page':'Add New Status Page'}}</b-button>
                            </b-col>
                        </b-row>
                    </px-card>
                </form>
            </div>
        </div>
    </div>
</template>


<script>
import StatusPageController from "../../services/statuspageController";
import MonitorsController from "../../services/monitorController";
import Multiselect from 'vue-multiselect'
export default{
    name : "AddStatus",
    components:{Multiselect},
    props:{add_status_modal:Boolean,edit_status:Boolean,status_data:{type:Object}},
    data(){
        return {
            showPassword:false,
            disable_submit:false,
            selected_monitors:[],
            monitor_opt:[],
            all_monitors:[],
            input:{robotsMetaTag:null,name:'',homepageurl:'',customDomain:''},
            robotsMetaTag_opt:[
              { value: null, text:'Please Select' },
              { value: 'index', text:'Index' },
              { value: 'noindex', text:'NoIndex' }
            ]
        }
    },
    computed:{
        validate_url(){
            return this.validateUrl(this.input.homepageurl)
        },
        validate_domain(){
            return this.validateDomain(this.input.customDomain)
        }
    },
    mounted(){
        this.getMonitors();
        if(this.edit_status){
            this.input = this.status_data
        }
    },
    methods:{
        async getMonitors() {
        let response = await MonitorsController.getMonitors();
        if (response.result) {
            response.data.map((t) => {
                let typeBadge = '';
                switch (t.type) {
                    case 1:
                        typeBadge = 'HTTP(s)';
                        break;
                    case 2:
                        typeBadge = 'Keyword';
                        break;
                    case 3:
                        typeBadge = 'Ping';
                        break;
                    case 4:
                        typeBadge = 'Port';
                        break;
                    case 5:
                        typeBadge = 'Heartbeat';
                        break;
                    case 6:
                        typeBadge = 'Page Speed';
                        break;
                    case 7:
                        typeBadge = 'Page Screenshot';
                        break;
                    default:
                        typeBadge = '';
                }

                this.monitor_opt.push({ value: t._id, text: t.name, type: t.type, isDown: t.isDown, badge: typeBadge });
                this.all_monitors.push({ value: t._id, text: t.name, type: t.type, isDown: t.isDown, badge: typeBadge });
            });
        }
        if(this.edit_status){

            this.input.monitors.map((m)=>{
                for(var i=0;i<this.monitor_opt.length;i++){
                    if(this.monitor_opt[i]['value']== m){
                        this.selected_monitors.push(this.monitor_opt[i])
                    }
                }
            })
        }
        },
        addTag (newTag) {
        const tag = {
            name: newTag,
            code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
        }
        this.options.push(tag)
        this.value.push(tag)
        },
       async onSubmit(){
        let monitors = []
        if(this.selected_monitors.length > 0){
          this.selected_monitors.map((t)=>{ monitors.push(t.value)})
        }  else{
            this.$toasted.show('Please select any monitor', {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
            return false
        }
        this.disable_submit = true
        this.input.monitors = monitors
        // if(this.payload != {}){
                var response
                if(this.edit_status){
                     var p = { "spid": this.input._id, "changePayload":this.input }
                     response= await StatusPageController.updateStatusPage(p)
                }
                else{
                    response= await StatusPageController.addStatusPage(this.input)
                }
                if(response.result){
                    this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
                    this.$emit('statusmodal')
                }
                else{
                    this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
                }
            // }
            this.disable_submit = false
        },
        validateUrl(str) {
            if (!str) {
                return false;
            }
            try {
                const url = new URL(str);
                return url.protocol === 'http:' || url.protocol === 'https:';
            } catch (error) {
                return false;
            }
        },
        validateDomain(domain){
            var regexp = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;
            if (regexp.test(domain)){ return true; }
            else{ return false; }
        },
        togglePassword(id){
          const password = document.getElementById(id);
          this.showPassword = this.showPassword === false ? true: false
          const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
          password.setAttribute('type', type);
        }
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>