<template>
    <div>
      <Breadcrumbs main="" title="User interaction events" />
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <b-row class="mt-3">
                  <b-col md="6">
                    <b-input-group >
                      <b-input-group-prepend >
                        <span class="input-group-text search-bg"><i class="icofont icofont-search-alt-2"></i></span>
                      </b-input-group-prepend>
                      <b-form-input
                        class="col-md-5"
                        v-model="filter"
                        placeholder="Type to Search"
                      ></b-form-input>
                    </b-input-group>
                  </b-col>
  
                  <b-col class="text-right col-md-6 col-12">
                    <b-form-group
                      label-cols="10"
                      label="Per page"
                      class="mb-0"
                    >
                      <b-form-select
                        class="col-md-10"
                        v-model="perPage"
                        :options="pageOptions"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <div class="col-md-12" v-if="loading">
                  <h6 class="sub-title mb-0 text-center">Loading...</h6>
                    <div class="loader-box">
                      <div class="loader-30"></div>
                    </div>
                </div> 
                <div v-else class="table-responsive datatable-vue text-left">
                  <b-table
                    show-empty
                    stacked="md"
                    :items="items"
                    :filter="filter"
                    :current-page="currentPage"
                    :fields="tablefields"
                    :per-page="perPage"
                    @filtered="onFiltered"
                  >
                  <template #table-caption>User Interaction Events</template>
  
                  <template #cell(message)="data">
                    <p class="res_text">{{ data.item.message }}</p>
                  </template>
                  <template #cell(actions)="data">
                    <span class="text-info" v-for="action in data.item.actions" :key="action"><span class="badge badge-primary ml-1">{{ alerts[action] }}</span> </span>
                  </template>
                  <template #cell(createdAt)="data">
                    <span>{{ changeDateFormate(data.item.createdAt) }}</span>
                  </template>
                  <template #cell(action)="data">
                    <feather class="text-info ml-2" type="edit" title="Edit" @click="editUie(data.item)"></feather>                  
                  </template>

                  </b-table>
                </div>
  
                <b-col class="mt-3 p-0">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    class="my-0"
                  ></b-pagination>
                </b-col>
          </div>
        </div>
      </div>
      <b-modal id="modal-uie" size="lg" title="User Interaction Event" class="theme-modal" @hidden="closeModal()" v-model="uie_modal" no-close-on-backdrop hide-footer>
        <div class="p-2">
        <b-row>
            <b-col>
                <label class="font-weight-bold">Subject :</label>
                    <b-form-input
                      v-model="uie_data.subject"
                      placeholder="Subject"
                    ></b-form-input>
            </b-col>
         </b-row>
         <b-row class="mt-2">
            <b-col>
                <label class="font-weight-bold">Message :</label>
                    <b-textarea
                        rows="4" max-rows="15"
                      v-model="uie_data.message"
                      placeholder="Message"
                    ></b-textarea>
            </b-col>
         </b-row>

         <b-row class="mt-2">
            <b-col>
                <label class="font-weight-bold">Actions</label>
                <multiselect size="sm" v-model="alerts_data" tag-placeholder="Add this as new tag" placeholder="Select alerts" 
                            label="text" track-by="value" :options="alert_opt" :multiple="true" :close-on-select="true" :taggable="true" @tag="addTag"></multiselect>
            </b-col>
         </b-row>

         <b-row class="mt-3">
            <b-col class="text-right">
                <b-spinner v-if="updating" label="Spinning"></b-spinner>
                <b-button class="col-md-12" v-else variant="primary" @click="updateUie()">Update</b-button>
            </b-col>
         </b-row>
        </div> 
        <template #modal-footer>
          <b-button @click="closeModal()" color="danger">Close</b-button>
          </template>
        </b-modal>
    </div>
  </template>
  
  
  
  
  <script>
  import moment from "moment";
  import ManagerController from "../../../services/managerController";
  import ModeratorController from "../../../services/moderatorController";
  import Multiselect from 'vue-multiselect';
  export default {
    name: "UIE",
    components:{Multiselect},
    data(){
      return{
          alerts_data:[],
          uie_data:{},
          uie_modal:false,
          edit_uie:false,
          loading:false,
          updating:false,
          items:[],
          totalRows:0,
          filter:null,
          currentPage: 1,
          perPage: 10,
          pageOptions: [5, 10, 50],
          tablefields: [
                  { key: "type", label: "Type", sortable: false },
                  { key: "subject", label: "Subject", sortable: false },
                  { key: "message", label: "Message", sortable: false },
                  { key: "actions", label: "Actions", sortable: false },
                  { key: "createdAt", label: "CreatedAt", sortable: true },
                  { key: "action", label: "Action", sortable: false },
          ],
          alert_opt:[
              { value: null, text:'Please Select' },
              { value: '1', text:'Email' },
              { value: '2', text:'Skype' },
              { value: '3', text:'Telegram' },
              { value: '4', text:'Web push' },
              { value: '5', text:'Webhook' },
              { value: '6', text:'Message' },
              { value: '7', text:'Call' },
              { value: '8', text:'Slack' },
              { value: '9', text:'Zappier webhook' }
            ],
         alerts:{1:'Email',2:'Skype',3:'Telegram',4:'Web push',5:'Webhook',6:'Message',7:'Call',8:'Slack',9:'Zappier webhook'},
      }
    },
    mounted(){
      this.getUie()
    },
    methods:{
      getUserRole(){
        let user  = JSON.parse(window.localStorage.getItem('userinfo'))
        return user.role
      },
      async getUie(){
        this.loading = true;
              this.items = []
              let response
              
              if(this.getUserRole() == 'manager'){
                response = await ManagerController.getUserInteractEvents()
              } else if(this.getUserRole() == 'moderator'){
                response = await ModeratorController.getUserInteractEvents()
              }
              
              if (response.result) {
                  this.items = response.data.reverse();
                  this.totalRows = this.items.length;
              }
              this.loading = false;
      },
      async updateUie(){
        this.updating = true;
        let payload ={
            "eid":this.uie_data._id,
            "changeObj": {
                "subject": this.uie_data.subject,
                "message": this.uie_data.message,
                "actions": this.alerts_data.map(t=>t.value)
            }
        }
        let response

        if(this.getUserRole() == 'manager'){
            response = await ManagerController.updateUserInteractEvent(payload)
        } else if(this.getUserRole() == 'moderator'){
            response = await ModeratorController.updateUserInteractEvent(payload)
        }
        if (response.result) {
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
            this.closeModal();
            this.getUie();
        }
        this.updating = false;
      },
      editUie(data){
        this.uie_data = {}
        this.uie_data = data
        this.alerts_data = data.actions.map((t)=>{
            return this.alert_opt.find(e=> e.value == t)
        })
        this.uie_modal = true
        this.edit_uie = true;
      },
      closeModal(){
          this.uie_data = {}
          this.uie_modal = false
          this.edit_uie = false
          this.alerts_data = []
          this.getUie();
      },
      changeDateFormate(d) {
        return moment(d).format("D/M/YYYY, HH A");
      },
      onFiltered(filteredItems) {
          // Trigger pagination to update the number of buttons/pages due to filtering
          this.totalRows = filteredItems.length;
          this.currentPage = 1;
      },
      addTag (newTag) {
            const tag = {
                text: newTag,
                value: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
            }
            this.options.push(tag)
            this.value.push(tag)
        },
    }
  };
  </script>
  <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>