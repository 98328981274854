<template>
  <div id="app">
    <!-- Loader starts-->
    <div class="loader-wrapper" :class="{'loderhide' : !show }">
      <div class="loader loader-7">
        <div class="line line1"></div>
        <div class="line line2"></div>
        <div class="line line3"></div>
      </div>
    </div>
    <!-- Loader ends-->

    <!--<Main/>-->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      show: true
    };
  },
  mounted() {
    this.timeOut();
  },
  methods: {
    timeOut() {
      // this.basketAddSuccess = true;
      var self = this;
      setTimeout(function() {
        self.show = false;
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
.loader-wrapper.loderhide {
  display: none;
}
</style>
<style>
.link_txt{
    color: blue;
    cursor: pointer;
}
tr{
  box-shadow: 0px 0px 1px 3px rgba(239, 238, 238, 0);
}
@media (max-width: 767.98px){
  tr{
  box-shadow: 0px 0px 1px 3px rgb(93, 92, 92);
}
}
</style>

<style>
th{
    text-align: unset !important;
}
.table th, .table td {
    padding: 0.25rem !important;
}
.google_btn{
  cursor: pointer;
  border:1px solid rgb(88, 88, 245);
  border-radius: 5px;
}
.google_btn:hover{
  border:2px solid rgb(0, 0, 255);
}
.cust_txt {
    max-width: 16em;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}
.cust_txt:hover {
    max-width: auto;
    text-overflow: clip;
    white-space: normal;
    overflow: auto;
}
.res_text {
  display: inline-block;
  max-width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  cursor: pointer;
}
.res_text:hover {
  max-width: auto;
  text-overflow: clip;
  white-space: normal;
}
.copy_cursor:hover{
  cursor: copy;
}
.card .card-header {
  padding: 20px !important;
}
.cursor_pointer{
  cursor: pointer;
}

.breadcrumbHeader > .breadcrumb {
  background-color: inherit !important;
  margin-bottom: 0;
  padding: 0rem 0rem;
  margin: 0rem 0rem !important;
}

.breadcrumb-item + .breadcrumb-item::before {
  padding-right: 1px !important;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 1px !important;
}


/* .page-wrapper .page-main-header {
  height: 8% !important;
  align-items: start !important;
}
.page-wrapper .page-body-wrapper .page-body {
  margin-top: 7% !important;
} */

.page-main-header .main-header-right .mobile-sidebar {
  padding-right: 0px !important;
}

.page-main-header .main-header-right .nav-right.right-menu ul li .user-header .media-body span + span {
  font-size: 14px !important;
  font-weight: normal !important;
}

.page-main-header .main-header-right .nav-right.right-menu ul {
  justify-content: flex-end !important;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu {
  margin-top: 1rem !important;
  padding: 0rem 0rem !important;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a {
  font-size: 0.9vw !important;
  font-weight: 400 !important;
  transition: 0.05s !important;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a.active {
  font-size: 1vw !important;
  font-weight: 500 !important;
  color: #DDE2FF !important;
  background-color: rgba(159, 162, 180, 0.08) !important;
  border-left: 3px solid rgba(221, 226, 255, 1) !important;
}

.showBreadcrumbs {
  display: none !important;
}

.page-main-header .main-header-right {
  padding: 0.5rem 1rem 0.5rem 1rem !important;
  margin: 0rem 0rem !important;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  box-shadow: none !important;
  border-radius: 0% !important;
  border-bottom: 1px solid #CFCFCF !important;
  background-color: #ffffff !important;
}

.custom-card-shadow {
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
}

.custom-font-16 {
  font-size: 0.85vw !important;
}

.custom-font-18 {
  font-size: 1vw !important;
}

.custom-font-20 {
  font-size: 1.1vw !important;
}

.custom-font-22 {
  font-size: 1.2vw !important;
}

.custom-font-24 {
  font-size: 1.25vw !important;
}

.custom-font-26 {
  font-size: 1.354vw !important;
}

.custom-font-30 {
  font-size: 1.4vw !important;
}

.custom-font-34 {
  font-size: 2.05vw !important;
}

.custom-font-36 {
  font-size: 2.145vw !important; 
}

.custome-title{
  font-weight: 600 !important; 
  font-size: 2.145vw !important; 
}

.add-monitor-btn-green {
  font-size: 1.1vw;
  padding: 0.335rem 1.70rem ;
  background-color: #2FB234 !important;
  color: white !important;
  cursor: pointer !important;
  border-radius: 10px !important;
}

.add-monitor-btn-green:hover {
  background-color: #139818 !important;
}

.add-monitor-btn-dark {
  font-size: 1.1vw;
  padding: 0.335rem 1.70rem;
  background-color: #ffffff !important;
  color: rgb(0, 0, 0) !important;
  border: 1px solid black !important;
  cursor: pointer !important;
  border-radius: 10px !important;
}

.add-monitor-btn-dark:hover {
  background-color: #000000 !important;
  color: white !important;
}

.custom-btn-dark {
  font-size: 1.1vw;
  padding: 0.332rem 2.1vw !important;
  background-color: #ffffff !important;
  color: rgb(0, 0, 0) !important;
  border: 0.6px solid rgba(0, 0, 0, 0.6) !important;
  cursor: pointer !important;
  border-radius: 10px !important;
}

.custom-btn-dark:hover {
  background-color: #000000 !important;
  color: white !important;
}

.custom-btn {
  font-size: 1.1vw !important;
  padding: 0.16rem 2vw !important;
  border-radius: 8px !important;
}

.showMonitorBtn {
 display: none !important;
}

.form-group {
  margin-bottom: 0rem !important;
}

.multiselect {
  font-size: 1vw !important;
}

.multiselect .multiselect__input, 
.multiselect .multiselect__option,
.multiselect .multiselect__placeholder,
.multiselect .multiselect__tags-wrap,
.multiselect .multiselect__tags,
.multiselect .multiselect__content {
  font-size: 1vw !important;
  border-radius: 8px !important;
}


.multiselect .multiselect__option--highlight {
  font-size: 1.2vw;
}

.multiselect__single {
  font-size: 1.2vw !important;
  padding: 0.7vh 0vw !important;
}

.multiselect__select {
  margin: 0.26vw !important;
}

.custom-select {
  padding: 0.375rem 1.4rem 0.375rem 0.75rem !important;
}

.search-bg{
  background-color: inherit !important;
  border-right: 0px;
  height: auto;
}

.gray-font{
  color: rgb(98, 98, 98);
}

.media-body label {
  margin-bottom: 0rem !important;
  margin-top: 0.2rem !important;
}

.switch-outline .switch-state:before {
  background-color: #E70000 !important;
}

.switch-outline .switch input:checked + .switch-state.bg-success:before {
  background-color: #188107 !important;
}

.datatable-vue table {
  border: inherit !important;
}

.datatable-vue .table thead th {
  border-bottom: 0.8px solid #2F2F2F !important;
}


.datatable-vue .table th, .table td {
  border-top: inherit !important;
  border-bottom: 0.6px solid #00000099 !important;
}

.datatable-vue table tbody tr:nth-child(odd),
.datatable-vue table tbody tr:nth-child(odd) td:first-child,
.datatable-vue table tbody tr:nth-child(even) td:first-child,
.datatable-vue table tbody tr:nth-child(even):hover td   {
  background-color: inherit !important;
}

.datatable-vue .incidents-table tbody tr td,
.datatable-vue .events-table tbody tr td{
  padding: 0.5rem 0rem !important;
}

/* Alert contact table */
.alert-table .table thead th:nth-child(5){
  width: 18% !important;
}

.card {
  box-shadow: none !important;
  -wekit-box-shadow: none !important;
}

.card .card-header h5:not(.mb-0) {
  font-family: 'Work Sans', sans-serif !important;
}

.card .card-header h5 {
  font-size: 1.2rem !important;
}

.cus-search-input .input-group-text {
  background-color: #ffffff !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border: 0.6px solid rgba(58, 58, 58, 1);
  padding-right: 0rem !important;
}

.cus-search-input .form-control {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  border: 0.6px solid rgba(58, 58, 58, 1);
}

.col-form-label {
  width: 7vw !important;
  margin-bottom: 0% !important;
  padding-top: calc(0.4rem + 1px) !important;
}

.pagination {
  justify-content: end;
}

.badge-gray {
  background-color: rgb(120, 120, 120) !important;
  color: #ffffff !important;
}

@media (max-width: 768px) {
  /* .page-wrapper .page-main-header {
    height: 5% !important;
    align-items: start !important;
  }

  .page-wrapper .page-body-wrapper .page-body {
    margin-top: 15% !important;
  } */

  .multiselect__single,
  .custom-font-16,
  .custom-font-18,
  .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a {
    font-size: 0.8rem !important;
  }

  .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a.active {
    font-size: 0.9rem !important;
  }
  
  .page-wrapper .page-main-header .main-header-left .logo-wrapper {
    margin-left: 90px !important;
  }

  .res_text {
    max-width: 12em;
  }

  .custom-font-20 {
    font-size: 0.9rem !important;
  }

  .custom-font-22 {
    font-size: 1rem !important;
  }

  .custom-font-24,
  .custom-font-26 {
    font-size: 1.15rem !important;
  }

  .custom-font-30,
  .custom-font-34,
  .custome-title {
    font-size: 1.2rem !important;
  }

  .custom-font-36 {
    font-size: 1.7rem !important; 
  }

  .add-monitor-btn-green {
    font-size: 0.8rem !important;
    padding: 0.526rem 0.72rem !important;
    border-radius: 5px !important;
  }

  .custom-btn-dark {
    font-size: 0.8rem !important;
    padding: 0.2rem 0.29rem  !important;
    border-radius: 5px !important;
  }

  .custom-btn {
    font-size: 0.8rem !important;
    padding: 0.2rem 7.1vw !important;
    border-radius: 5px !important;
  }

  /* .cust-monitor-btn-cont > .col-md-6 {
    width: auto !important; 
  } */

  .multiselect,
  .multiselect .multiselect__input, 
  .multiselect .multiselect__option,
  .multiselect .multiselect__placeholder,
  .multiselect .multiselect__tags-wrap,
  .multiselect .multiselect__tags,
  .multiselect .multiselect__content,
  .multiselect__single  {
    font-size: 16px !important;
  }

  .multiselect__content-wrapper {
    width: auto !important;
  }


  .multiselect .multiselect__option--highlight {
    font-size: 16px;
  }

  .table.b-table.b-table-stacked-md > tbody > tr > [data-label]::before {
    text-align: left !important;
    width: 40% !important;
  }

  .datatable-vue .table tbody {
    border: 1px solid black !important;
    border-radius: 10px !important;
  }

  .datatable-vue .table tbody tr {
    box-shadow: inherit !important;
    -webkit-box-shadow: inherit !important;
    padding: 0.5rem 0.5rem !important;
    border-bottom: 1px solid black !important;
  }

  .datatable-vue .table tbody tr > td {
    border: 0px !important;
  }

  .datatable-vue .table tbody tr:last-child {
    border-bottom: 0px !important;
  }

  .datatable-vue .table th, .table td {
    border-top: inherit !important;
    border-bottom: inherit !important;
  }

  .datatable-vue .incidents-table tbody tr td{
    padding: 0.25rem !important;
  }

  .table-responsive {
    overflow-x: visible !important;
  }

  .page-wrapper .page-body-wrapper .page-header .breadcrumb {
    margin-top: 0px !important;
  }

  .page-wrapper .page-body-wrapper .page-header .breadcrumb .breadcrumb-item a,
  .page-wrapper .page-body-wrapper .page-header .breadcrumb li {
    font-family: 'Work Sans', sans-serif !important;
  } 

  .switch {
    width: 46px !important;
    height: 24px !important; 
  }

  .switch-state:before {
    width: 15px !important;
    height: 15px !important;
    left: 1px !important;
  }

  .media-body label {
    margin-bottom: 0rem !important;
    margin-top: 0rem !important;
  }

  .col-form-label {
    width: 100px !important;
  }
}

@media (max-width: 1200px) {
  .showBreadcrumbs {
    display: block !important;
  }

  .breadcrumbHeader {
    display: none !important;
  }

  .showMonitorBtn {
    display: block !important;
  }
  .hideMonitorBtn {
    display: none !important;
  }
  
  .add-monitor-btn-dark {
    font-size: 0.8rem !important;
    padding: 0.5rem !important;
    border-radius: 5px !important;
    background-color: #2FB234 !important;
    color: white !important;
    cursor: pointer !important;
    border: inherit !important;
  }

  .add-monitor-btn-dark:hover {
    background-color: #139818 !important;
  }
}
</style>
