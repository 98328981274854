<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 report p-3">
        <div slot="with-padding">
          <div class="col-md-12 custom-card-shadow ">
            <h2 class="ml-4 p-3 custome-title">Quick Stats</h2>

            <b-row>
              <!-- UP Monitors -->
              <div class="col-md-4 c_card text-center">
                <router-link class="text-white" :to="{ path: '/app/monitor?type=up' }">
                  <div class="circle-chart">
                    <svg class="custom-chart-card" width="67%" height="67%" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                      <g transform="rotate(-90 100 100)">
                        <circle r="80" cy="100" cx="100" stroke-width="15" stroke="#eee" fill="none"></circle>
                        <circle r="80" cy="100" cx="100" stroke-width="15" stroke="#188107" fill="none"
                          stroke-dasharray="502" :stroke-dashoffset="502 - (502 * (monitor_report.up / totalMonitors))"
                          style="transition: stroke-dashoffset 0.6s ease;"></circle>
                      </g>
                    </svg>
                    <div class="circle-value">{{ monitor_report.up }}</div> 
                  </div>
                  <div style="font-size: 24px; margin: 20px 0px;"><span class="dot-green mr-1">●</span><span class="text-dark">UP Monitors</span></div>
                </router-link>
              </div>

              <!-- Down Monitors -->
              <div class="col-md-4 c_card text-center">
                <router-link class="text-white" :to="{ path: '/app/monitor?type=down' }">
                  <div class="circle-chart">
                    <svg class="custom-chart-card" width="67%" height="67%" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                      <g transform="rotate(-90 100 100)">
                        <circle r="80" cy="100" cx="100" stroke-width="15" stroke="#eee" fill="none"></circle>
                        <circle r="80" cy="100" cx="100" stroke-width="15" stroke="#E70000" fill="none"
                          stroke-dasharray="502" :stroke-dashoffset="502 - (502 * (monitor_report.down / totalMonitors))"
                          style="transition: stroke-dashoffset 0.6s ease;"></circle>
                      </g>
                    </svg>
                    <div class="circle-value">{{ monitor_report.down }}</div> 
                  </div>
                  <div style="font-size: 24px; margin: 20px 0px;"><span class="dot-red mr-1">●</span><span class="text-dark">Down Monitors</span></div>
                </router-link>  
              </div>

              <!-- Paused Monitors -->
              <div class="col-md-4 c_card text-center">
                <router-link class="text-white" :to="{ path: '/app/monitor?type=pause' }">
                  <div class="circle-chart">
                    <svg class="custom-chart-card" width="67%" height="67%" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                      <g transform="rotate(-90 100 100)">
                        <circle r="80" cy="100" cx="100" stroke-width="15" stroke="#eee" fill="none"></circle>
                        <circle r="80" cy="100" cx="100" stroke-width="15" stroke="#9E9E9E" fill="none"
                          stroke-dasharray="502" :stroke-dashoffset="502 - (502 * (monitor_report.pause / totalMonitors))"
                          style="transition: stroke-dashoffset 0.6s ease;"></circle>
                      </g>
                    </svg>
                    <div class="circle-value">{{ monitor_report.pause }}</div> 
                  </div>
                  <div style="font-size: 24px; margin: 20px 0px;"><span class="dot-gray mr-1">●</span><span class="text-dark">Paused Monitors</span></div>
                  </router-link>
              </div>
            </b-row>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import DashboardController from "../../services/dashboardController";
export default {
  name: "report",
  components: {},
  data() {
    return {
      monitor_report: { up: 0, down: 0, pause: 0 },
      totalMonitors: 0,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let response = await DashboardController.getMonitorReport();
      if (response.message) {
        if (response.data.up) { this.monitor_report.up = response.data.up; }
        if (response.data.down) { this.monitor_report.down = response.data.down; }
        if (response.data.pause) { this.monitor_report.pause = response.data.pause; }
        this.totalMonitors = response.data.up + response.data.down + response.data.pause;
      }
    },
  },
};
</script>


<style>
.c_card {
  padding: 0px 0px;
  background: #fff;
  border-radius: 10px;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.dot-green {
  color: #188107;
  font-size: 18px;
  background-color: #188107;
  border-radius: 25%;
  padding: 3px 5px 0px 5px;
}

.dot-red {
  color: #E70000;
  font-size: 18px;
  background-color: #E70000;
  border-radius: 25%;
  padding: 3px 5px 0px 5px;
}

.dot-gray {
  color: #9E9E9E;
  font-size: 18px;
  background-color: #9E9E9E;
  border-radius: 25%;
  padding: 3px 5px 0px 5px;
}

.circle-chart {
  position: relative;
}

.circle-value {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 36px;
  font-weight: bold;
  color: #333;
}

.custom-chart-card {
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  padding: 0.5rem;
  background-color: #F6FAFF !important;
}

/* .c_card:first-child .circle-chart{
  margin-left: 2rem;
}

.c_car:last-child .circle-chart{
  margin-right: 2rem !important;
} */

.uptime-card {
  padding: 15px;
  margin: 10px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.uptime-value {
  font-size: 32px;
  font-weight: bold;
  color: #333;
}

.uptime-label {
  margin-top: 5px;
  font-size: 14px;
  color: #666;
}
</style>