<template>
    <div>
        <button v-if="user_role != 'manager' && user_role != 'moderator' && add_monitor_btn == true" @click="AddMonitor"
            class="btn custom-font-24" :class="btn_class">+ Add New Monitor</button>
    </div>
</template>
<script>
export default {
    data() {
        return {
            user_role: '',
            add_monitor_btn: false,
            btn_class: 'add-monitor-btn-green'
        }
    },
    mounted() {
        let user = JSON.parse(window.localStorage.getItem('userinfo'))
        this.user_role = user.role
        
        let pathName = this.$route.name
        if (pathName === 'Dashboard' || pathName === 'Incidents' || pathName === 'Referral' || pathName === 'Profile') {
            this.btn_class = 'add-monitor-btn-green';
        } else {
            this.btn_class = 'add-monitor-btn-dark';
        }

        if (this.$router.history.current.path !== '/app/monitor') {
            this.add_monitor_btn = true;
        }
    },
    methods: {
        AddMonitor() {
            this.$root.$data.showAddMoinitor = true;
            this.$router.push("/app/monitor");
        },
    }
}
</script>