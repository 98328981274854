<template>
  <div class="container-fluid">
    <div class="page-header showBreadcrumbs">
      <div class="row">
        <div class="col">
          <div class="page-header-left">
            <h3 class="custom-font-26">{{title}}</h3>
            <ol class="breadcrumb">
              <li class="breadcrumb-item" v-if="user_role == 'manager'"><router-link :to="{ path: '/manager/users' }">Home</router-link></li>
              <li class="breadcrumb-item" v-else><router-link :to="{ path: '/app/dashboard' }">Home</router-link></li>
              <li class="breadcrumb-item" v-if="main">{{main}}</li>
              <li class="breadcrumb-item active" v-if="title">{{title}}</li>
            </ol>
          </div>
        </div>
        <div class="col text-right" :class="{ 'd-none': $router.history.current.path === '/app/monitor' }">
          <add-monitor-button />
          <!-- <Bookmark /> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bookmark from  './bookmark'
import AddMonitorButton from './addMonitorButton.vue';
export default {
  data(){
    return{
      user_role:'',
      add_monitor_btn:false,
      screenWidth: window.innerWidth
    } 
  },
  mounted(){
    let user  = JSON.parse(window.localStorage.getItem('userinfo'))
    this.user_role = user.role
  },
  props: {
    title: {
      default: 'home'
    },
    main: {
      default: ''
    }
  },
  components:{
    Bookmark,
    AddMonitorButton
  },
  // methods:{
  //   AddMonitor(){
  //     // let uid = Math.floor(Math.random() * 100000);
  //     // if(this.$router.history.current.fullPath != '/app/monitor'){
  //       this.$root.$data.showAddMoinitor = true;
  //       this.$router.push("/app/monitor");
        
  //     // }
      
  //     // window.location.reload();
  //   }
  // }
}
</script>
