<template>
    <div>
        <div class="row m-0">
            <Breadcrumbs main="" title="Dashboard" />
            <b-col class="text-right mr-2 hideMonitorBtn">
                <add-monitor-button />
            </b-col>
        </div>
        <div class="row">
            <div class="col">
                <report-vue></report-vue>
            </div>
        </div> 
        <div class="row">
            <div class="col">
                <div class="row mt-3 justify-content-center">
                    <div class="col-md-11 text-left p-4 report">
                            <div class="row d-flex">
                                <div class="col">
                                    <h2 class="custome-title">Overall Uptime</h2>
                                </div>
                                <div class="col text-right custom-uptime-select">
                                    <b-form-select class="col-md-6" name="type" size="sm" v-model="monitor" :options="monitor_options" @input="getUptime()" required>
                                    </b-form-select>
                                </div>
                            </div>
                            <div class="row p-2 hideUptimeCards">
                                <div class="col text-center custom-uptime-card">
                                    <h1 class="custome-title">
                                    {{ overalluptime.d7.uptime.toFixed(3) }}%
                                    </h1>
                                    <h3 class="custom-font-26 f-w-600">Last 7 days</h3>
                                </div>
                                <div class="col text-center custom-uptime-card">
                                    <h1 class="custome-title">
                                    {{ overalluptime.d30.uptime.toFixed(3) }}%
                                    </h1>
                                    <h3 class="custom-font-26 f-w-600">Last 30 days</h3>
                                </div>
                                <div class="col text-center custom-uptime-card">
                                    <h1 class="custome-title">
                                    {{ overalluptime.d90.uptime.toFixed(3) }}%
                                    </h1>
                                    <h3 class="custom-font-26 f-w-600">Last 90 days</h3>
                                </div>
                            </div>
                            <div class="row p-3 showUptimeCards">
                                <div class="col text-center custom-uptime-card">
                                    <h1 class="custom-font-36 f-w-600">
                                    {{ overalluptime.d7.uptime.toFixed(3) }}%
                                    </h1>
                                    <h3 class="custom-font-26 f-w-600">Last 7 days</h3>
                                </div>
                                <div class="col text-center custom-uptime-card">
                                    <h1 class="custom-font-36 f-w-600">
                                    {{ overalluptime.d30.uptime.toFixed(3) }}%
                                    </h1>
                                    <h3 class="custom-font-26 f-w-600">Last 30 days</h3>
                                </div>
                                <div class="col text-center custom-uptime-card">
                                    <h1 class="custom-font-36 f-w-600">
                                    {{ overalluptime.d90.uptime.toFixed(3) }}%
                                    </h1>
                                    <h3 class="custom-font-26 f-w-600">Last 90 days</h3>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-6 col-12">
                <incedent-vue :monitor_data="monitor_opt"></incedent-vue>
            </div>
            <div class="col-md-6 col-12">
                <event-vue :monitor_data="monitor_opt"></event-vue>
            </div>
        </div>
        
        
    </div>
</template>


<script>
import IncedentVue from "./Incedent.vue";
import EventVue from "./Events.vue";
import ReportVue from "./Report.vue";
import MonitorsController from "../../services/monitorController";
import DashboardController from "../../services/dashboardController";
import AddMonitorButton from "../components/addMonitorButton.vue";
export default{
    name:'dashboard',
    components: { IncedentVue, EventVue, ReportVue, AddMonitorButton },
    data(){
        return {
            monitor_opt:{},
            monitor_options : [{ value: null, text: "All Monitors Uptime"}] ,
            overalluptime:{d7: { uptime: 0 },d30: { uptime: 0 },d90: { uptime: 0 }},
            monitor:null
        }
    },
    mounted(){
        this.getMonitors();
        this.getUptime()
    },
    methods:{
    async getMonitors() {
      let response = await MonitorsController.getMonitors();
      if (response.result) {
        response.data.map((t) => {
        //   this.monitor_opt.push({ value: t._id, text: t.name, type:t.type,isDown:t.isDown });
          this.monitor_options.push({ value: t._id, text: t.name, type:t.type,isDown:t.isDown });
        });
      }
    },
    async getUptime(){
        var response 
        if(this.monitor){
            let payload = '?mid='+this.monitor
            response = await DashboardController.getUptimeByMid(payload)
        }
        else{
          response = await DashboardController.getUptime()
        }
        if(response.result){
            this.overalluptime = response.data
        }
    }
    }
}
</script>

<style scoped>
.custom-uptime-card {
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25) !important;
    padding: 1rem;
    border-radius: 10px;
}

.custom-uptime-card:first-child,
.custom-uptime-card:nth-child(2) {
    margin-right: 3rem !important;
}

.custom-uptime-select .custom-select{
    background-color: #2FB234 !important;
    color: white !important;
    font-size: 1.3vw !important;
    border-radius: 10px !important;
}

.showUptimeCards{
    display: none;
}

@media (max-width: 767.98px) {
    .hideUptimeCards{
        display: none;
    }

    .showUptimeCards{
        display: block;
    }

    .custom-uptime-card {
        margin-bottom: 1rem !important;
    }

    .custom-uptime-select .custom-select{
        font-size: 0.8rem !important;
        border-radius: 8px !important;
        padding: 0rem 0.1rem !important;
        height: calc(1.5em + 1rem + 2px) !important;
    }

}

</style>