<template>
  <div>
    <Breadcrumbs main="" title="Users" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
                <b-row class="">
                <b-col md="6" class="mb-3">
                  <b-input-group>
                    <b-input-group-prepend >
                      <span class="input-group-text search-bg"><i class="icofont icofont-search-alt-2"></i></span>
                    </b-input-group-prepend>
                    <b-form-input
                      class="col-md-5"
                      v-model="filter"
                      placeholder="Type to Search"
                    ></b-form-input>
                  </b-input-group>
                </b-col>

                <b-col md="6" class="mb-3">
                  <b-form-group
                    label-cols="2"
                    label="Per page"
                    class="mb-0 datatable-select"
                  >
                    <b-form-select
                      v-model="perPage"
                      :options="pageOptions"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="col-md-12" v-if="loading">
                <h6 class="sub-title mb-0 text-center">Loading...</h6>
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
              </div> 
              <div v-else class="table-responsive datatable-vue text-left">
                <b-table
                  show-empty
                  stacked="md"
                  :items="items"
                  :filter="filter"
                  :current-page="currentPage"
                  :per-page="perPage"
                  @filtered="onFiltered"
                  :fields="tablefields"
                >
                <template #table-caption>User List.</template>

                 <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(email)="data">
                  <b class="">{{ data.item.email }}</b>
                </template>
                <template #cell(role)="data">
                  <b-select v-model="data.item.role" :options="['user','moderator']" size="sm" @change="roleUpdate(data.item)"></b-select>
                </template>
                <template #cell(isactive)="data">
                  <div class="media-body icon-state switch-outline">
                        <label class="switch">
                        <input type="checkbox" :checked=data.item.isactive @change="statusUpdate(data.item)">
                        <span class="switch-state" :class="data.item.isactive?'bg-success':'bg-danger'"></span>
                        </label>
                    </div>
                </template>
                
                <template #cell(login)="data">
                    <span class="link_txt text-primary" @click="RedirectLogin(data.item)">Login</span>
               
                </template>
                <template #cell(plan_name)="data">
                    <span class="font-weight-normal">{{data.item.plan_name}}</span>
                </template>
                <template #cell(expiry_date)="data">
                  <b class="">{{ changeDateFormate(data.item.expiry_date) }}</b>
                </template>
                <template #cell(allocate_plan)="data">
                  <b-button variant="primary" @click="allocatePlanModal(data.item)">Allocate Plan</b-button>
                </template>
                </b-table>
              </div>

              <b-col class="mt-3 p-0">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  class="my-0"
                ></b-pagination>
              </b-col>
          <b-modal id="modal-monitor" size="lg" title="Allocate Plan" class="theme-modal" v-model="allocatePlan" no-close-on-backdrop hide-footer>
            <div class="p-5">
            <b-row>
              <b-col cols="3" class="font-weight-bold">
                Select Plan 
              </b-col>
              <b-col cols="5">
                <b-form-select name="type" size="sm" v-model="input.plan_id" :options="plan_opt"  required></b-form-select>
              </b-col>
            </b-row><hr>
            <b-row class="mt-3 font-weight-bold">
              <b-col cols="3">Select expiry date</b-col>
              <b-col cols="5"><b-form-input type="datetime-local" v-model="input.expiry_date"></b-form-input></b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col md="3" class="font-weight-bold">
                    Message Count
                </b-col>
                <b-col md="4">
                    <b-form-input size="sm" type="number" name="messagecount" v-model="input.messageLeft" required></b-form-input>
                </b-col>
            </b-row><hr>
            <b-row>
                <b-col md="3" class="font-weight-bold">
                    Call Count
                </b-col>
                <b-col md="4">
                    <b-form-input size="sm" type="number" name="callcount" v-model="input.callLeft" required></b-form-input>
                </b-col>
            </b-row>
            <hr>
            <b-row class="mt-5">
              <b-col class="text-right">
                <b-button variant="success" @click="AllocatePlan(user_data)">Save</b-button>
              </b-col>
            </b-row>
          </div>
            <template #modal-footer>
            <b-button @click="allocatePlan = false" color="danger">Close</b-button>
            </template>
          
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import UserController from "../../../services/userController"
import ManagerController from "../../../services/managerController"
import UserAuth from '../../auth/js/index'
import moment from "moment";
export default {
    name:'Users',
    data(){
        return{
            allocatePlan:false,
            input:{plan_id:null},
            plan_opt:[{"value":null,"text":'select plan to allocate'}],
            plans:[],
            items:[],
            totalRows:0,
            filter:null,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 50],
            loading:false,
            tablefields: [ 'index',
                          { key: "email", label: "Email", sortable: true },
                          { key: "role", label: "Role", sortable: true },
                          { key: "isactive", label: "Status", sortable: false },
                          {key: 'login',label: 'Login'},
                          {key: 'plan_name',label: 'Plan Name'},
                          {key: 'messageLeft',label: 'Message left'},
                          {key: 'callLeft',label: 'Call left'},
                          {key: 'expiry_date',label: 'Expiry Date'},
                          {key:'allocate_plan', label: 'Allocate Plan'}
                        ],
            userinfo :[],
            user_data:[]
        }
    },
    mounted(){
      this.userinfo = JSON.parse(window.localStorage.getItem('userinfo'))
        this.getUsers()
        this.getPlans()
    },
    methods: {
     async roleUpdate(data){
      if(confirm("Are you sure you want to change role?")){
        var payload = {"userId": data._id, "tobeModify": {"role":data.role}}
        let response = await UserController.editUser(payload)
        if(response.result){
          this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
        }
        else{
          this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
        }
      }
      },
      allocatePlanModal(data){
        this.user_data = data
        const filteredOptions = this.plan_opt.filter(e => data.plan_name === e.text);
        if (filteredOptions.length > 0) {
          this.input.plan_id = filteredOptions[0].value;
        } else {
          this.plan_id = null
        }
        this.input.expiry_date = data.expiry_date.slice(0,-5)
        this.input.messageLeft = data.messageLeft
        this.input.callLeft = data.callLeft
        this.allocatePlan = true
      },
        async getUsers(){
            this.loading = true;
            this.items = []
            let response = await UserController.getAllUsers()
            if (response.result) {
                this.items = response.message.filter(t=>{
                    if(t.role != 'manager'){
                        return true;
                    }
                });
                this.totalRows = this.items.length;
            }
            else{
              this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
            }
            this.loading = false;
        },
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async statusUpdate(data){
        let status = true
        if(data.isactive == true){
            status = false
        }
        if (data.isactive == false) {
          data.isactive = true;
        } else {
          data.isactive = false;
        }
        var payload = {"usersId": data._id, "isactive": status}
        let response = await UserController.changeStatus(payload)
        if(response.result){
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
            // this.getUsers()
        }
      },
      async RedirectLogin(d){
      var postdata = { email: this.userinfo.email, password: 'test', emailToBeLogin: d.email}
      try {
        let response = await ManagerController.wildlogin(postdata)
        if(response.result == true){
          UserAuth.localLogin(response.message);
          this.$router.replace('/auth/login')
        }
      } catch(e){
        console.error("Error in login")
        console.log(e)
      }
    },
    async getPlans(){
      this.loading = true;
        let response = await ManagerController.getPlans()
        if (response.result) {
            this.plans = response.data;
            this.plans.map((t)=>{
                this.plan_opt.push({"value":t._id,"text":t.plan_name})
            })
        }
        this.loading = false;
    },
    async AllocatePlan(data){
      if(this.input.plan_id){
      this.loading = true;
      var payload = {'allocated_planid': data.allocated_planid ,'changePayload':{"planid":this.input.plan_id,"expiry_date":this.input.expiry_date,"messageLeft":this.input.messageLeft,"callLeft":this.input.callLeft}}
      let response = await ManagerController.allocatePlan(payload)
      if(response.result){
        this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
      }
      else{
        this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
      }
      this.allocatePlan = false
      this.getUsers()
      }
      else{
        this.$toasted.show('Please Select Plan', {theme: 'outline',position: "bottom-center", type: 'error', duration: 3000});
      }
      },
    changeDateFormate(d) {
    return moment(d).format("D/M/YYYY, HH A");
    },
    }
}
</script>