import Vue from 'vue'
import Router from "vue-router";

import Userauth from '../auth/js/index'

import Body from '../components/body'
import Auth from '../auth/auth'

/* Auth */
const login = () => import('../auth/login')
const Verification = () => import('../auth/verification')
const ForgetPassword = () => import('../auth/forgetpassword')
const Register = () => import('../auth/register')

const Users = () => import('../views/manager/Users')
const Monitors = () => import('../views/manager/Monitors')
const Incedence = () => import('../views/manager/Incedence')
const Events = () => import('../views/manager/Events')
const Plans = () => import('../views/manager/Plans')
const Activity = () => import('../views/manager/Activity')
const Affiliate = () => import('../views/manager/Affiliate')
const AffiliateLogs = () => import('../views/components/AffiliateLogs')
const UserInfo = () => import('../views/moderator/UserInfo')
const ReferalLogs = () => import('../views/manager/ReferalLogs')
const BlacklistAff = () => import('../views/manager/BlacklistAff')
const Uie = () => import('../views/components/UserInteractionEvent')

/* maintenance */
const Maintenance = () => import('../pages/maintenance')

/* Error Pages */
const Error400 = () => import('../errors/error_400')
const Error401 = () => import('../errors/error_401')
const Error403 = () => import('../errors/error_403')
const Error404 = () => import('../errors/error_404')
const Error500 = () => import('../errors/error_500')
const Error503 = () => import('../errors/error_503')

const Monitor = () => import('../views/Monitor')
// const MonitorReport = () => import('../views/MonitorReport')
const IncidenceList = () => import('../views/IncidentsList')
const AlertContacts = () => import('../views/AlertContacts')
const StatusPage = () => import('../views/StatusPage')
const Dashboard = () => import('../views/Dashboard')
const Notify = () => import('../views/Notify')
const SubUser = () => import('../views/SubUser')
const Profile = () => import('../views/Profile')
const Referral = () => import('../views/Referral')
const MaintenanceWindows = () => import('../views/maintenance/Maintenance')
const Locations = () => import('../views/help/Locations')

// component

Vue.use(Router)

const routes = [
{ path: '/', redirect: { name: 'Login' }},
{
  path: '/auth',
  component: Auth,
  children: [
  {
    path: 'login',
    name: 'Login',
    component: login,
    meta: {
      title: ' login',
    }
  },
  {
    path: 'register',
    name: 'register',
    component: Register,
    meta: {
      title: ' Register',
    }
  },
  {
    path: 'forgetpassword',
    name: 'ForgetPassword',
    component: ForgetPassword,
    meta: {
      title: 'ForgetPassword',
    }
  },
  {
    path: 'verification',
    name: 'Verification',
    component: Verification,
    meta: {
      title: ' Verification',
    }
  },
  ]
},
{
  path: '/app',
  component: Body,
  children: [
  {
    path: 'dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      title: 'Dashboard',
    }
  },
  {
    path: 'monitor',
    name: 'Monitor',
    component: Monitor,
    meta: {
      title: 'Monitor',
    }
  },
  // {
  //   path: 'monitorreport/:id?',
  //   name: 'Monitor Report',
  //   component: MonitorReport,
  //   meta: {
  //     title: 'Monitor Report',
  //   }
  // },
  {
    path: 'incident/:mid?',
    name: 'Incidents',
    component: IncidenceList,
    meta: {
      title: 'Incidents',
    }
  },
  {
    path: 'alertcontacts',
    name: 'AlertContacts',
    component: AlertContacts,
    meta: {
      title: 'Alert Contacts',
    }
  },
  {
    path: 'statuspage',
    name: 'StatusPage',
    component: StatusPage,
    meta: {
      title: 'Status Page',
    }
  },
  {
    path: 'subuser',
    name: 'SubUser',
    component: SubUser,
    meta: {
      title: 'Sub User',
    }
  },
  {
    path: 'profile',
    name: 'Profile',
    component: Profile,
    meta: {
      title: 'Profile',
    }
  },
  {
    path: 'referral',
    name: 'Referral',
    component: Referral,
    meta: {
      title: 'Referral',
    }
  },
  {
    path: 'maintenancewindow',
    name: 'maintenancewindow',
    component: MaintenanceWindows,
    meta: {
      title: 'Maintenance Windows',
    }
  },
  {
    path:'locations',
    name:'Locations',
    component:Locations,
    meta: {
        title: 'Locations and IPs',
      }
  },
]
},
{
  path: '/manager',
  component: Body,
  children: [
  {
    path: 'users',
    name: 'Users',
    component: Users,
    meta: {
      title: ' Users',
    }
  },
  {
    path: 'monitors',
    name: 'Monitors',
    component: Monitors,
    meta: {
      title: ' Monitors',
    }
  },
  {
    path: 'incidents',
    name: 'ManagerIncidents',
    component: Incedence,
    meta: {
      title: 'Incidents',
    }

  },
  {
    path: 'events',
    name: 'Events',
    component: Events,
    meta: {
      title: 'Events',
    }

  },
  {
    path: 'plans',
    name: 'Plans',
    component: Plans,
    meta: {
      title: 'Plans',
    }

  },
  {
    path: 'activity',
    name: 'Activity',
    component: Activity,
    meta: {
      title: 'Activity',
    }
  },
  {
    path: 'affiliate',
    name: 'Affiliate',
    component: Affiliate,
    meta: {
      title: 'Affiliate',
    }

  },
  {
    path: 'blacklistaff',
    name: 'Blacklist Affiliates',
    component: BlacklistAff,
    meta: {
      title: 'Blacklist Affiliates',
    }

  },
  {
    path: 'affiliatelogs',
    name: 'Affiliate Logs',
    component: AffiliateLogs,
    meta: {
      title: 'AffiliateLogs',
    }
  },
  {
    path: 'referlogs',
    name: 'Referal Logs',
    component: ReferalLogs,
    meta: {
      title: 'Referal Logs',
    }
  },
  {
    path: 'uie',
    name: 'User Interaction Events',
    component: Uie,
    meta: {
      title: 'User Interaction Events',
    }
  }
]},
{
  path: '/moderator',
  component: Body,
  children: [
  {
    path: 'userInfo',
    name: 'UserInfo',
    component: UserInfo,
    meta: {
      title: 'UserInfo',
    }
  },
  {
    path: 'affiliatelogs',
    name: 'Affiliate Logs',
    component: AffiliateLogs,
    meta: {
      title: 'AffiliateLogs',
    }
  },
  {
    path: 'uie',
    name: 'User Interaction Events',
    component: Uie,
    meta: {
      title: 'User Interaction Events',
    }
  }
]
},
{
  path:'/notify',
  name:'Notify',
  component:Notify,
  meta: {
      title: 'Notify',
    }
},
{
  path:'/error-400',
  name:'Error400',
  component:Error400,
  meta: {
      title: 'Error400',
    }
},
{
  path:'/error-401',
  name:'Error401',
  component:Error401,
  meta: {
      title: 'Error401',
    }
},
{
  path:'/error-403',
  name:'Error403',
  component:Error403,
  meta: {
      title: 'Error403',
    }
},
{
  path:'/error-404',
  name:'Error404',
  component:Error404,
  meta: {
      title: 'Error404',
    }
},
{
  path:'/error-500',
  name:'Error500',
  component:Error500,
  meta: {
      title: 'Error500',
    }
},
{
  path:'/error-503',
  name:'Error503',
  component:Error503,
  meta: {
      title: 'Error503',
    }
},
{
  path:'/maintenance',
  name:'Maintenance',
  component:Maintenance,
  meta: {
      title: 'Maintenance',
    }
},
];

const router = new Router({
  routes,
  base: '/',
  mode: 'history', // hash or history
  linkActiveClass: "active",
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

router.beforeEach((to, from, next) => {
  if(to.meta.title)
      document.title = to.meta.title;
      const  path = ['/auth/login','/auth/register','/auth/forgetpassword','/auth/verification'];
   if (path.includes(to.path) || (Userauth.isAuthenticated() && Userauth.checAlive())){
    return next();
   }
   next('/auth/login')

  // firebase.auth().onAuthStateChanged(() => {

  //   if(to.meta.title)
  //     document.title = to.meta.title;
  //   const CurrentUser = firebase.auth().currentUser;
  //   const  path = ['/auth/login','/auth/register'];
  //   if (path.includes(to.path) || CurrentUser && Userauth.isAuthenticated()){
  //     console.log('authenticated')
  //     return next();
  //   }
  //   next('/auth/login')
  // });
});


export default router
